import { Row, Col, Button, Divider } from "antd"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { MyContext } from "../../../App"
import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"
import { Back } from "../../ui/Back"


import { BannerCta } from "../../sections/BannerCta"

const Pay = () => {

      const { UserData, setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      const finishPayment = () => {
            navigate("/complete")
      }

      return (

            <>

                  <Header theme="dark" />

                  <Content isFullWidth>

                        <Row justify="center">

                              <Col xs={22} md={18}>

                                    <Row justify="start">

                                          <Col span={12}>

                                                <Back align="left" style={{ marginTop: 100, marginBottom: 0 }} />

                                                <h1 style={{ marginTop: 20, fontSize: 44, marginBottom: 10 }}>Make payment</h1>
                                                <p style={{ fontSize: 18, marginTop: -10, marginBottom: 25, opacity: 0.5 }}>
                                                      Safe & secure. 256 bit encryption. Standard.
                                                </p>

                                                <Row>

                                                      <Col>

                                                            <Divider />

                                                            <p style={{ fontSize: 18, marginTop: 50, marginBottom: 25, opacity: 0.5 }}>
                                                                  This is a temporary placeholder. Integration in progress between Voli Limited, Lloyds Commercial Banking and FreedomPay Inc. Testing payment platform ETA July 2024.
                                                            </p>
                                                            <Button
                                                                  type="primary"
                                                                  style={{ marginTop: 50, marginBottom: 125 }}
                                                                  size="large"
                                                                  onClick={() => { finishPayment() }}
                                                            >
                                                                  Pay £{UserData.policy.rating.price.toLocaleString()} Online
                                                            </Button>

                                                      </Col>



                                                </Row>

                                          </Col>

                                    </Row>

                              </Col>

                        </Row>

                        <BannerCta style={{ marginTop: 0 }} />

                  </Content>

                  <Footer />

            </>

      )
}

export default Pay