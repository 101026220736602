import { Row, Col } from "antd"
import styles from './Products.module.css'

export function Products() {

      return (

            <div >

                  <Row align="justify" gutter={25} style={{ marginTop: 75 }}>

                        <Col span={11} className="gutter-row">

                              <div id={styles.ImageGridCell} style={{ height: 550 }}></div>
                              <div id={styles.ImageGridCell} style={{ height: 50 }}></div>

                        </Col>

                        <Col span={5} className="gutter-row">

                              <div id={styles.ImageGridCell} style={{ height: 625 }}></div>

                        </Col>

                        <Col span={8} className="gutter-row">

                              <div id={styles.ImageGridCell} style={{ height: 200 }}></div>
                              <div id={styles.ImageGridCell} style={{ height: 400 }}></div>

                        </Col>

                  </Row>

            </div>

      )
}