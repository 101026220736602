import { useContext } from "react"
import { Row, Col } from "antd"
import Divider from '../../ui/Divider'
import Logo from '../../ui/Logo'
import styles from './Footer.module.css'
import { MyContext } from "../../../App"
import IconWithText from "../../ui/IconWithText"
import { CiLink as LinkIcon } from "react-icons/ci"


export function Footer() {

      const { UserData } = useContext(MyContext)

      const vw = UserData?.device?.viewport?.width || false

      return (

            <div id={styles.Footer}>

                  <Row gutter={16} justify="center" align="top">

                        <Col xs={23} md={20}>

                              <Row>

                                    <Col xs={24} md={6} style={vw <= 768 ? { marginBottom: 50 } : {}}>

                                          <Logo color="rgba(255,255,255,0.2)" height="20" />

                                    </Col>

                                    <Col xs={24} md={10} style={{ paddingLeft: 25, borderLeft: '1px solid rgba(255,255,255,0.1)', marginBottom: vw <= 768 ? 50 : null }} >

                                          <a target="_blank" href="https://assets.firstfix.co/docs/policy-summary.pdf"><IconWithText icon={<LinkIcon />} text="Policy Summary" /></a>
                                          <a target="_blank" href="https://assets.firstfix.co/docs/policy-wording.pdf"><IconWithText icon={<LinkIcon />} text="Policy Wording" /></a>
                                          <a target="_blank" href="https://assets.firstfix.co/docs/ipid.pdf"><IconWithText icon={<LinkIcon />} text="Insurance Product Information Document (IPID)" /></a>
                                          <Divider theme="dark" customStyle={{ width: "50%" }} />
                                          <a target="_blank" href="https://assets.firstfix.co/docs/toba.pdf"><IconWithText icon={<LinkIcon />} text="Terms of business" /></a>
                                          <a target="_blank" href="https://assets.firstfix.co/docs/privacy.pdf"><IconWithText icon={<LinkIcon />} text="Privacy, Data & Cookies" /></a>

                                    </Col>

                                    <Col xs={24} md={8}>

                                          <div>
                                                © 2024 Voli Ltd. All rights reserved.
                                          </div>

                                          <Divider theme="dark" />

                                          <div>
                                                Firstfix is a trading name of Voli Limited. Voli Limited is authorised and regulated by the Financial Conduct Authority. Registration XXXXXXX
                                          </div>

                                          <Divider theme="dark" />

                                          <div>
                                                Registered in England No. 15554073 Registered Office: Suite 22 Trym Lodge, 1 Henbury Road, Westbury-On-Trym, Bristol, United Kingdom, BS9 3HQ
                                          </div>

                                          <Divider theme="dark" />

                                          <div>
                                                v1.0.1
                                          </div>

                                    </Col>

                              </Row>

                        </Col>

                  </Row >

            </div >

      )
}