import React from 'react'
import ReactDOM from 'react-dom/client'
import '../src/_assets/styles/global.css'
import { App } from './App'
import WebFont from 'webfontloader'

WebFont.load({
      google: {
            families: [ 'Roboto:400,700', 'Urbanist:300,400,500,600,700', 'Manrope:200,300,400,500,600,700,800,900', 'Josefin Sans:100,200,300,400,500,600,700', 'Fugaz One:400', 'Racing Sans One:400', 'Tilt Warp:400' ]
      }
})


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
      <App />
)
