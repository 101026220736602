
export default function Divider({ theme = "light", customStyle }) {

      const defaultStyle = {
            outline: 0,
            margin: '15px 0',
            border: theme === "light" ? '1px solid rgba(0,0,0,0.2)' : '1px solid rgba(255,255,255,0.05)'
      }

      const blendedStyle = { ...defaultStyle, ...customStyle }

      return (
            <hr style={blendedStyle} />
      )

}
