import { Row, Col, Button, Form, Input, Divider } from "antd"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { MyContext } from "../../../App"
import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"
import { Back } from "../../ui/Back"
import styles from './Setup.module.css'
import IconWithText from "../../ui/IconWithText"
import { IoSearch as SearchIcon } from "react-icons/io5"



import { BannerCta } from "../../sections/BannerCta"

const Setup1 = () => {

      const { setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      const [ form ] = Form.useForm()

      const onFinish = (data) => {
            acceptQuote(data)
      }

      const acceptQuote = (data) => {
            setUserData({
                  policy: {
                        user: {
                              title: 'Mr',
                              firstName: 'Oliver',
                              lastName: 'Kingston',
                              email: data.email
                        }
                  }
            })
            navigate("/setup/two")
      }

      return (

            <>

                  <Header theme="dark" />

                  <Content isFullWidth>

                        <Row justify="center">

                              <Col xs={22} md={18}>

                                    <Row justify="center">

                                          <Col span={24}>

                                                <Back align="left" style={{ marginTop: 100, marginBottom: 0 }} />

                                                <h1 style={{ marginTop: 20, fontSize: 44, marginBottom: 10 }}>Tell us about yourself</h1>
                                                <p style={{ fontSize: 18, marginTop: -10, marginBottom: 25, opacity: 0.5 }}>
                                                      We'll use this information to create your policy documents.
                                                </p>

                                                <Row>

                                                      <Col xs={24} md={14}>

                                                            <Form
                                                                  form={form}
                                                                  name="userForm"
                                                                  onFinish={onFinish}
                                                                  layout="vertical"
                                                                  initialValues={{ countryCode: '+44' }} // Default values can be set here
                                                            >

                                                                  <Divider />

                                                                  <h2>PERSONAL INFORMATION</h2>

                                                                  <Row gutter={32}>
                                                                        <Col className={styles.colLHS} span={4}>
                                                                              <Form.Item
                                                                                    name="title"
                                                                                    label="Title"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                              <Form.Item
                                                                                    name="firstName"
                                                                                    label="First Name"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                        <Col span={10}>
                                                                              <Form.Item
                                                                                    name="lastName"
                                                                                    label="Surname"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                  </Row>

                                                                  <Row gutter={32} align="bottom">
                                                                        <Col className={styles.colLHS} span={8}>
                                                                              <Form.Item
                                                                                    name="dobD"
                                                                                    label="Date of birth"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                              <Form.Item
                                                                                    name="dobM"
                                                                                    label=""
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                              <Form.Item
                                                                                    name="dobY"
                                                                                    label=""
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                  </Row>

                                                                  <Row gutter={32}>
                                                                        <Col className={styles.colLHS} span={12}>
                                                                              <Form.Item
                                                                                    name="email"
                                                                                    label="Email"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                              <Form.Item
                                                                                    name="telephone"
                                                                                    label="Telephone"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                  </Row>

                                                                  <Divider />

                                                                  <h2>ABOUT YOUR BUSINESS</h2>

                                                                  <Row gutter={32}>
                                                                        <Col className={styles.colLHS} span={24}>
                                                                              <Form.Item
                                                                                    name="type"
                                                                                    label="Business Type"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                  </Row>

                                                                  <Row gutter={32} align="bottom">
                                                                        <Col className={styles.colLHS} span={12}>
                                                                              <Form.Item
                                                                                    name="year"
                                                                                    label="Year Business Started"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                              <Form.Item
                                                                                    name="revenue"
                                                                                    label="Annual Revenue"
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                  </Row>

                                                                  <Divider />

                                                                  <h2>YOUR ADDRESS</h2>

                                                                  <Row gutter={32}>
                                                                        <Col className={styles.colLHS} span={8}>
                                                                              <Form.Item
                                                                                    name="search"
                                                                                    label={<IconWithText icon={<SearchIcon />} iconLocation="right" text="Postcode Search" />}
                                                                              >
                                                                                    <Input />
                                                                              </Form.Item>
                                                                        </Col>
                                                                  </Row>

                                                                  <Divider />

                                                                  <Button style={{ marginBottom: 125 }} type="primary" htmlType="submit" size="large">
                                                                        Next
                                                                  </Button>
                                                            </Form>

                                                      </Col>

                                                </Row>

                                          </Col>

                                    </Row>

                              </Col>

                        </Row>

                        <BannerCta style={{ marginTop: 0 }} />

                  </Content>

                  <Footer />

            </>

      )
}

export default Setup1