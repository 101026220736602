import { Row, Col, Button } from "antd"
import styles from './BannerCta.module.css'
import { TbShieldPlus as ShieldIcon } from "react-icons/tb"
import { useNavigate } from "react-router-dom"



export function BannerCta() {

      const navigate = useNavigate()

      return (

            <div id={styles.BannerCta} style={{ width: '100%' }}>

                  <Row justify="center">

                        <Col span={18}>

                              <Row align="middle">

                                    <Col flex="300px">

                                          <ShieldIcon style={{ fontSize: 250, opacity: 0.1 }} />

                                    </Col>

                                    <Col span={8}>


                                          <img src={`${process.env.PUBLIC_URL}/assets/images/logos/rsa.png`} alt="RSA Logo" />
                                          <h2 style={{ fontSize: 28, textTransform: 'uppercase' }}>UNDERWRITTEN BY</h2>
                                          <p style={{ marginTop: 12, opacity: 0.4 }}>Royal & Sun Alliance Insurance Ltd are one of the world’s longest-serving general insurance companies. Our products help you manage life’s risks and make things right when they go wrong.</p>
                                          <Button onClick={() => navigate("/quote")} style={{ marginTop: 25 }} type="primary" size="small">Get a quote today</Button>

                                    </Col>

                              </Row>

                        </Col>

                  </Row>

            </div>

      )

}