import { Row, Col, Button, Form, Segmented, Divider } from "antd"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { MyContext } from "../../../App"
import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"
import { Back } from "../../ui/Back"
import styles from './Setup.module.css'
import IconWithText from "../../ui/IconWithText"
import { IoSearch as SearchIcon } from "react-icons/io5"



import { BannerCta } from "../../sections/BannerCta"

const Setup2 = () => {

      const { setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      const [ form ] = Form.useForm()

      const onFinish = (data) => {
            acceptQuote(data)
      }

      const acceptQuote = (data) => {
            setUserData({
                  policy: {
                        user: {
                              title: 'Mr',
                              firstName: 'Oliver',
                              lastName: 'Kingston',
                              email: data.email
                        }
                  }
            })
            navigate("/review")
      }

      return (

            <>

                  <Header theme="dark" />

                  <Content isFullWidth>

                        <Row justify="center">

                              <Col xs={22} md={18}>

                                    <Row justify="center">

                                          <Col span={24}>

                                                <Back align="left" style={{ marginTop: 100, marginBottom: 0 }} />

                                                <h1 style={{ marginTop: 20, fontSize: 44, marginBottom: 10 }}>Statement of facts</h1>
                                                <p style={{ fontSize: 18, marginTop: -10, marginBottom: 25, opacity: 0.5 }}>
                                                      We'll use this information to create your policy documents.
                                                </p>

                                                <Row>

                                                      <Col xs={24} md={18}>

                                                            <Form
                                                                  form={form}
                                                                  name="userForm"
                                                                  onFinish={onFinish}
                                                                  layout="vertical"
                                                                  initialValues={{ countryCode: '+44' }} // Default values can be set here
                                                            >

                                                                  <Divider />

                                                                  <h2>HAVE YOU OR ANY OF YOUR FELLOW DIRECTORS OR PARTNERS, AS INDIVIDUALS OR IN CONNECTION WITH ANY BUSINESS, BEEN:</h2>

                                                                  <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>
                                                                        <Col span={1}>a)</Col>
                                                                        <Col span={18}>
                                                                              Charged or convicted in the UK with a criminal offence other than a motoring conviction
                                                                        </Col>
                                                                        <Col span={4} offset={1}><Segmented value="No" options={[ 'Yes', 'No' ]} block /></Col>
                                                                  </Row>

                                                                  <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>
                                                                        <Col span={1}>b)</Col>
                                                                        <Col span={18}>
                                                                              Declined or refused insurance cover or had cover cancelled or been subject to special terms in respect of any cover that you have asked us to insure under this policy?
                                                                        </Col>
                                                                        <Col span={4} offset={1}><Segmented value="No" options={[ 'Yes', 'No' ]} block /></Col>
                                                                  </Row>

                                                                  <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>
                                                                        <Col span={1}>c)</Col>
                                                                        <Col span={18}>
                                                                              Declared bankrupt, subject to bankruptcy proceedings or to any voluntary or mandatory insolvency or winding up proceedings either as individuals or in connection with any other business?
                                                                        </Col>
                                                                        <Col span={4} offset={1}><Segmented value="No" options={[ 'Yes', 'No' ]} block /></Col>
                                                                  </Row>

                                                                  <Row align="middle" style={{ marginTop: 75, marginBottom: 75 }}>
                                                                        <Col span={1}>d)</Col>
                                                                        <Col span={18}>
                                                                              Disqualified under the Company Directors Disqualification Act 1986 or any subsequent legislation?
                                                                        </Col>
                                                                        <Col span={4} offset={1}><Segmented value="No" options={[ 'Yes', 'No' ]} block /></Col>
                                                                  </Row>

                                                                  <Divider />

                                                                  <Button style={{ marginBottom: 125 }} type="primary" htmlType="submit" size="large">
                                                                        Next
                                                                  </Button>
                                                            </Form>

                                                      </Col>

                                                </Row>

                                          </Col>

                                    </Row>

                              </Col>

                        </Row>

                        <BannerCta style={{ marginTop: 0 }} />

                  </Content>

                  <Footer />

            </>

      )
}

export default Setup2