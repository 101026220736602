import { Row, Col } from "antd"
import { BiSolidStar as StarIcon, BiSolidStarHalf as HalfStarIcon } from "react-icons/bi"
import { VscQuote as QuoteIcon } from "react-icons/vsc"
import styles from './Reviews.module.css'



export function Reviews() {

      return (

            <div id={styles.Reviews}>

                  {/* <Row style={{ marginTop: 150 }}>

                        <Col span={6}>

                              <div className="superscript">TESTIMONIAL</div>

                        </Col>

                        <Col span={10}>

                              <h3 style={{ marginTop: -10 }}>More power, more efficient</h3>
                              <p style={{ marginTop: -20, maxWidth: '80%' }}>Is a nutritional supplement for increased health and vitality. Featuring 1000mg of organically-grown, cannabidiol flavour.</p>

                        </Col>

                        <Col span={8} style={{ opacity: 0.6, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>

                              <div className={styles.ReviewStars}>

                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <StarIcon />
                                    <HalfStarIcon />

                              </div>

                        </Col>

                  </Row> */}

                  <Row style={{ marginTop: 150, marginBottom: 120 }}>

                        <Col span={22}>

                              <div className="TestimonialCard">

                                    <Row>

                                          <Col span={2}><QuoteIcon style={{ fontSize: 54, color: 'rgba(0,0,0,0.6)' }} /></Col>
                                          <Col span={8} style={{ fontSize: 34, marginTop: 10 }}>
                                                <div>Easy to use website and transparent pricing. Highly recommended.</div>
                                                <div style={{ marginTop: 25, fontSize: 24 }}>~ MR A N OTHER</div>
                                          </Col>

                                    </Row>
                              </div>

                        </Col>

                  </Row>

            </div>

      )
}