import { Row, Col, Table, Popover, Tag, Space, Divider, Button } from "antd"
import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MyContext } from "../../../App"
import { Back } from "../../ui/Back"
import { delay } from "../../../_utils/helper"
import styles from './Quote.module.css'
import IconWithText from "../../ui/IconWithText"
import { IoPricetagsOutline as EssentialsIcon } from "react-icons/io5"
import { TbShieldPlus as PlusIcon } from "react-icons/tb"
import { IoInformationCircleOutline as InfoIcon } from "react-icons/io5"
import { IoCheckmarkCircleSharp as PositiveIcon } from "react-icons/io5"
import { IoCloseCircleSharp as NegativeIcon } from "react-icons/io5"
import { IoIosMore as MoreIcon } from "react-icons/io"
import { FiShoppingCart as CartIcon } from "react-icons/fi"



const Quote = () => {

      const navigate = useNavigate()

      const { UserData, setUserData, AppData } = useContext(MyContext)

      const [ cover, setCover ] = useState(UserData?.policy?.rating?.cover || 1000)
      const [ rating, setRating ] = useState(AppData?.rating?.bands || [])
      const [ ePrice, setE ] = useState(null)
      const [ pPrice, setP ] = useState(null)

      const getQuote = async () => {
            await delay(250)
            const [ price ] = rating.filter(item => item.cover === cover)
            setE(price.essentials)
            setP(price.plus)
      }

      useEffect(() => {
            getQuote()
      }, [ cover ])

      const selectCover = (value) => {

            setUserData({
                  policy: {
                        rating: {
                              cover: value
                        }
                  }
            })

            setCover(value)

      }

      const confirmCover = (plan) => {

            setUserData({
                  policy: {
                        rating: {
                              plan: plan === "P" ? "Plus" : "Essentials",
                              price: plan === "P" ? parseFloat(pPrice) : parseFloat(ePrice)
                        }
                  }
            })

            navigate("/setup/one")

      }

      const positiveIcon = <PositiveIcon style={{ fontSize: 20, verticalAlign: 'sub', color: 'green' }} />
      const negativeIcon = <NegativeIcon style={{ fontSize: 20, verticalAlign: 'sub', color: 'red' }} />

      const More = ({ content = "..." }) => (
            <Popover content={content}>
                  <MoreIcon className="clickable" style={{ fontSize: 20, verticalAlign: 'sub', opacity: 0.4 }} />
            </Popover>
      )

      const columns_covered = [
            {
                  title: '',
                  dataIndex: 'icon',
                  key: 'icon',
                  width: 50
            },
            {
                  title: <IconWithText icon={<InfoIcon />} text="Covered" iconLocation="right" style={{ fontSize: 18, opacity: 0.4 }} />,
                  dataIndex: 'label',
                  key: 'label',
                  render: (text) => <span>{text}</span>,
            },
            {
                  title: '',
                  dataIndex: 'description',
                  key: 'description',
                  width: 50
            }
      ]
      const data_covered = [
            {
                  key: '1',
                  icon: positiveIcon,
                  label: 'Tools',
                  description: <More content="We cover a wide range of tools" />
            },
            {
                  key: '2',
                  icon: positiveIcon,
                  label: 'Materials',
                  description: <More />
            },
            {
                  key: '3',
                  icon: positiveIcon,
                  label: 'Stock',
                  description: <More />
            },
            {
                  key: '4',
                  icon: positiveIcon,
                  label: '1x Laptop / Tablet',
                  description: <More />
            }
      ]

      const columns_not_covered = [
            {
                  title: '',
                  dataIndex: 'icon',
                  key: 'icon',
                  width: 50
            },
            {
                  title: <IconWithText icon={<InfoIcon />} text="Excluded" iconLocation="right" style={{ fontSize: 18, opacity: 0.4 }} />,
                  dataIndex: 'label',
                  key: 'label',
                  render: (text) => <span>{text}</span>,
            },
            {
                  title: '',
                  dataIndex: 'description',
                  key: 'description',
                  width: 50
            }
      ]
      const data_not_covered = [
            {
                  key: '1',
                  icon: negativeIcon,
                  label: 'Wear & tear',
                  description: <More />
            },
            {
                  key: '2',
                  icon: negativeIcon,
                  label: 'Manufacturing faults',
                  description: <More />
            },
            {
                  key: '3',
                  icon: negativeIcon,
                  label: 'Phones, sat navs, personal effects',
                  description: <More />
            },
            {
                  key: '4',
                  icon: negativeIcon,
                  label: 'Items unattended longer 24hrs',
                  description: <More />
            }
      ]

      const columns_events = [
            {
                  title: '',
                  dataIndex: 'icon',
                  key: 'icon',
                  width: 50
            },
            {
                  title: <IconWithText icon={<InfoIcon />} text="Events" iconLocation="right" style={{ fontSize: 18, opacity: 0.4 }} />,
                  dataIndex: 'label',
                  key: 'label',
                  render: (text) => <span>{text}</span>,
            },
            {
                  title: '',
                  dataIndex: 'description',
                  key: 'description',
                  width: 50
            }
      ]
      const data_events = [
            {
                  key: '1',
                  icon: positiveIcon,
                  label: 'Theft',
                  description: <More />
            },
            {
                  key: '2',
                  icon: positiveIcon,
                  label: 'Fire',
                  description: <More />
            },
            {
                  key: '3',
                  icon: negativeIcon,
                  label: 'Outright lies',
                  description: <More />
            }
      ]

      const columns_locations = [
            {
                  title: '',
                  dataIndex: 'icon',
                  key: 'icon',
                  width: 50
            },
            {
                  title: <IconWithText icon={<InfoIcon />} text="Locations" iconLocation="right" style={{ fontSize: 18, opacity: 0.4 }} />,
                  dataIndex: 'label',
                  key: 'label',
                  render: (text) => <span>{text}</span>,
            },
            {
                  title: '',
                  dataIndex: 'description',
                  key: 'description',
                  width: 50
            }
      ]
      const data_locations = [
            {
                  key: '1',
                  icon: positiveIcon,
                  label: 'Home',
                  description: <More />
            },
            {
                  key: '2',
                  icon: positiveIcon,
                  label: 'Work',
                  description: <More />
            },
            {
                  key: '3',
                  icon: negativeIcon,
                  label: 'Left van door ajar whilst in pub',
                  description: <More />
            }
      ]

      const content = (
            <Space direction="vertical">
                  {rating.map(item => (
                        <Tag
                              style={{ width: '100%' }}
                              key={item.level}
                              className="clickable"
                              onClick={() => selectCover(item.cover)}
                        >
                              £{item.cover.toLocaleString()}
                        </Tag>
                  ))}
            </Space>
      )

      return (

            <>

                  <Header theme="dark" />

                  <Content>

                        <Row>

                              <Col xs={24} md={12}>

                                    <Back align="left" style={{ marginTop: 100, marginBottom: 20, }} />

                                    <p style={{ fontSize: 16, opacity: 0.6, marginTop: 15 }}>We don't waste your time asking a million questions for a simple insurance quote. Just select the cover level you need and if you want to proceed, we'll have this wrapped up in under 5 minutes.</p>

                                    <p style={{ fontFamily: 'Urbanist, sans-serif', fontSize: 38, opacity: 1, marginTop: 25 }}>
                                          COVER LEVEL £{cover.toLocaleString()}
                                          <Popover placement="right" content={content}>
                                                <Tag style={{ marginLeft: 10, verticalAlign: 5 }} className="clickable">
                                                      CHANGE
                                                </Tag>
                                          </Popover>
                                    </p>

                              </Col>

                        </Row>

                        <Row style={{ marginTop: 5, marginBottom: 25 }}>

                              <Col xs={24} md={{ span: 9 }} >

                                    <div className={`${styles.planCard} ${styles.planE}`}>
                                          <IconWithText icon={<EssentialsIcon />} text="Essentials" style={{ fontSize: 22, opacity: 0.4 }} />
                                          <h1 style={{ fontSize: 56, marginTop: 5, marginBottom: 0 }}>£{ePrice}<span style={{ marginLeft: 5, fontSize: 16, opacity: 0.4 }}>per year</span></h1>
                                          <Divider style={{ borderTop: '1px solid rgba(255,255,255,0.1)' }} />
                                          <p>Claims are settled less 10% per year based on age.</p>
                                          <Divider style={{ borderTop: '1px solid rgba(255,255,255,0.1)' }} />
                                          <Button type="default" size="small" onClick={() => { confirmCover('E') }}>
                                                I WANT YOUR 'ESSENTIALS' PLAN
                                          </Button>
                                    </div>

                              </Col>

                              <Col xs={24} md={{ span: 9, offset: 1 }}>

                                    <div className={`${styles.planCard} ${styles.planP}`}>
                                          <IconWithText icon={<PlusIcon />} text="Plus" style={{ fontSize: 22, opacity: 0.4 }} />
                                          <h1 style={{ fontSize: 56, marginTop: 5, marginBottom: 0 }}>£{pPrice}<span style={{ marginLeft: 5, fontSize: 16, opacity: 0.4 }}>per year</span></h1>
                                          <Divider style={{ borderTop: '1px solid rgba(255,255,255,0.1)' }} />
                                          <p>Claims are settled at the original price you paid.</p>
                                          <Divider style={{ borderTop: '1px solid rgba(255,255,255,0.1)' }} />
                                          <Button type="default" size="small" onClick={() => { confirmCover('P') }}>
                                                I WANT YOUR 'PLUS' PLAN
                                          </Button>
                                    </div>

                              </Col>

                        </Row>

                        <Row style={{ marginTop: 50, marginBottom: 0 }}>

                              <Col xs={24} md={19}>

                                    <Table
                                          id="table_covered"
                                          pagination={false}
                                          style={{ marginBottom: 50 }}
                                          columns={columns_covered}
                                          dataSource={data_covered}
                                    />

                                    <Table
                                          id="table_not_covered"
                                          pagination={false}
                                          style={{ marginBottom: 50 }}
                                          columns={columns_not_covered}
                                          dataSource={data_not_covered}
                                    />

                              </Col>

                        </Row>

                        <Row style={{ marginTop: 0, marginBottom: 25 }}>

                              <Col xs={24} md={{ span: 9 }}>

                                    <Table
                                          id="table_events"
                                          pagination={false}
                                          style={{ marginBottom: 50 }}
                                          columns={columns_events}
                                          dataSource={data_events}
                                    />

                              </Col>

                              <Col xs={24} md={{ span: 9, offset: 1 }}>

                                    <Table
                                          id="table_covered"
                                          pagination={false}
                                          style={{ marginBottom: 50 }}
                                          columns={columns_locations}
                                          dataSource={data_locations}
                                    />

                              </Col>

                        </Row>

                  </Content >

                  <Footer />

            </>

      )
}

export default Quote