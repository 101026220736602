import { useContext } from "react"
import { MyContext } from "../../../App"
import { Row, Col, Button, Space } from "antd"
import styles from './Hero.module.css'
import { useNavigate } from "react-router-dom"
import heroImage from '../../../_assets/images/hero.webp' // Adjust the path as necessary


export function Hero() {

      const { setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      const selectCover = (value) => {

            setUserData({
                  policy: {
                        rating: {
                              cover: value
                        }
                  }
            })

            navigate("/quote")

      }


      return (

            <div id={styles.Hero} style={{ backgroundImage: `url(${heroImage})` }}>

                  <Row justify="center">

                        <Col xs={23} md={20}>

                              <Row justify="start">

                                    <Col xs={22} md={20} lg={14}>

                                          <div className={styles.pebble}>

                                                <div className="superscript" style={{ marginTop: 0, color: 'rgba(255, 255, 255, 0.8)' }}>ONLINE TOOL INSURANCE</div>

                                                <h1 style={{ marginTop: 5, fontSize: 44, marginBottom: 5, color: 'white' }}>What are your tools worth?</h1>

                                                <Space>
                                                      <Button onClick={() => { selectCover(1000) }} type="default" size="small">£1000</Button>
                                                      <Button onClick={() => { selectCover(2000) }} type="default" size="small">£2000</Button>
                                                      <Button onClick={() => { selectCover(3000) }} type="default" size="small">£3000</Button>
                                                      <Button onClick={() => { selectCover(4000) }} type="default" size="small">£4000</Button>
                                                      <Button onClick={() => { selectCover(5000) }} type="default" size="small">£5000+</Button>
                                                </Space>

                                          </div>

                                    </Col>

                              </Row>

                        </Col>

                  </Row>

            </div>

      )
}