const rating = {
      ipt: 0.12,
      fee: 0,
      excess: 100,
      bands: [
            {
                  cover: 500,
                  essentials: 34,
                  plus: 44
            },
            {
                  cover: 1000,
                  essentials: 48,
                  plus: 62
            },
            {
                  cover: 2000,
                  essentials: 64,
                  plus: 82
            },
            {
                  cover: 3000,
                  essentials: 78,
                  plus: 102
            },
            {
                  cover: 4000,
                  essentials: 92,
                  plus: 120
            },
            {
                  cover: 5000,
                  essentials: 104,
                  plus: 134
            },
            {
                  cover: 6000,
                  essentials: 118,
                  plus: 154
            },
            {
                  cover: 7000,
                  essentials: 130,
                  plus: 170
            },
            {
                  cover: 8000,
                  essentials: 144,
                  plus: 186
            },
            {
                  cover: 9000,
                  essentials: 156,
                  plus: 202
            },
            {
                  cover: 10000,
                  essentials: 168,
                  plus: 218
            }
      ]
}

export const config = {
      stage: {
            api: {
                  baseUrl: 'stage-api.moverinsurance.uk'
            },
            aws: {
                  cognito: {
                        clientId: '89o2lc13lnsskq77elm7ntf1n'
                  }
            },
            rating
      },
      prod: {
            api: {
                  baseUrl: 'api.moverinsurance.uk'
            },
            aws: {
                  cognito: {
                        clientId: '55hi357u8kpgr4k9r9q26doo4c'
                  }
            },
            rating
      }
}

