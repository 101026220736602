
import { useNavigate } from "react-router-dom"
import { BsReply as BackIcon } from "react-icons/bs"
import styles from './Back.module.css'

export function Back({ align, style }) {

      const navigate = useNavigate()

      const defaultStyle = {
            fontSize: 34,
            opacity: 0.1
      }

      const combinedStyle = { ...defaultStyle, ...style }

      return (

            <div className={styles.iconContainer} style={{ justifyContent: align }}>

                  <BackIcon className="clickable" onClick={() => navigate(-1)} style={combinedStyle} />

            </div>


      )
}