import { isHostAllowed } from "./_utils/helper"
import { createContext, useReducer, useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { config } from "./_config/config"
import Home from "./components/routes/Home"
import Quote from "./components/routes/Quote"
import Setup1 from "./components/routes/Setup1"
import Setup2 from "./components/routes/Setup2"
import Review from "./components/routes/Review"
import Pay from "./components/routes/Pay"
import Complete from "./components/routes/Complete"
import Error from "./components/routes/Error"
import { ScrollToTop } from "./components/ui/ScrollToTop"
import { useDevice } from "./hooks/useDevice"
import dayjs from "dayjs"
import _ from "lodash"

const stage = window.location.host.includes("local") || window.location.host.includes("stage") ? "stage" : "prod"

export const MyContext = createContext()

export function App() {
      const [ AppData, setAppData ] = useReducer(
            (oldData, newItem) => _.merge({}, oldData, newItem),
            { version: "0.1.0", env: stage, status: "", loading: false, ...config[ stage ] }
      )

      const [ UserData, setUserData ] = useReducer(
            (oldData, newItem) => _.merge({}, oldData, newItem),
            {}
      )

      // Use the modified useDevice hook // something new here maybe
      useDevice(setUserData)

      useEffect(() => {
            console.log(`AppData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(AppData))
      }, [ AppData ])

      useEffect(() => {
            console.log(`UserData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(UserData))
      }, [ UserData ])

      if (isHostAllowed()) {

            return (

                  <MyContext.Provider value={{ AppData, setAppData, UserData, setUserData }}>

                        <Router>

                              <ScrollToTop />

                              <Routes>

                                    <Route path="/" element={<Home />} />
                                    <Route path="/quote" element={<Quote />} />
                                    <Route path="/setup/one" element={<Setup1 />} />
                                    <Route path="/setup/two" element={<Setup2 />} />
                                    <Route path="/review" element={<Review />} />
                                    <Route path="/pay" element={<Pay />} />
                                    <Route path="/complete" element={<Complete />} />
                                    <Route path="/*" element={<Error />} />

                              </Routes>

                        </Router>

                  </MyContext.Provider>
            )
      } else {

            return (

                  <Error />

            )
      }
}
