
export default function MenuIcon({ onClick, height = 40, color = 'rgba(0,0,0,1)' }) {

      return (

            <div onClick={onClick} className="clickable">
                  <svg height={height} viewBox="319.3 1.1 95.5 110.2" xmlns="http://www.w3.org/2000/svg">
                        <g fill={color}>
                              <polygon points="323.5,87.4 319.3,111.3 390.5,111.3 394.7,87.4" />
                              <polygon points="394.7,45.6 339.5,45.6 335.3,69.5 390.5,69.5" />
                              <polygon points="410,1.1 338.7,1.1 334.5,25 405.8,25" />
                        </g>
                  </svg>
            </div >

      )

}


