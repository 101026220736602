import { Col } from 'antd'

const IconWithText = ({ icon, text, style, iconLocation = "left" }) => {

      if (iconLocation === "left") {

            return (
                  <Col flex="auto" style={{ display: 'flex', alignItems: 'center', ...style }}>
                        <div style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                              {icon}
                        </div>
                        <div>{text}</div>
                  </Col>
            )

      } else {

            return (
                  <Col flex="auto" style={{ display: 'flex', alignItems: 'center', ...style }}>
                        <div>{text}</div>
                        <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                              {icon}
                        </div>
                  </Col>
            )

      }
}

export default IconWithText
