import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"

import { Hero } from "../../sections/Hero"
import { LogoCarousel } from "../../sections/LogoCarousel"
import { Products } from "../../sections/Products"
import { Reviews } from "../../sections/Reviews"
import { BannerCta } from "../../sections/BannerCta"

function Home() {

      return (

            <>

                  <Header theme="light" />

                  <Content isFullWidth>
                        <Hero />
                  </Content>

                  <Content isFullWidth>
                        <h2 style={{ fontSize: 28, textAlign: 'center', marginTop: 100, textTransform: 'uppercase' }}>Cover the brands you love</h2>
                        <p style={{ textAlign: 'center', marginTop: -20, opacity: 0.4 }}>Protect your investment. We cover all makes and models</p>
                        <LogoCarousel itemNames={[ "dewalt", "makita", "festool", "hilti" ]} />
                  </Content>
                  <Content>
                        <Products />
                        <Reviews />
                  </Content>

                  <Content isFullWidth >
                        <BannerCta style={{ marginTop: 0 }} />
                  </Content>

                  <Footer />

            </>

      )
}

export default Home