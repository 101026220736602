

const Error = () => {

      return (
            <div className="App">
                  <div className="container">
                        <h1>whoops</h1>
                  </div>
            </div>
      )
}

export default Error