import { useState, useEffect, useContext } from 'react'
import { Row, Col, Drawer } from 'antd'
import Logo from '../../ui/Logo'
import MenuIcon from '../../ui/MenuIcon'
// import { IoBasketOutline as BasketIcon } from "react-icons/io5"
// import { PiShoppingCartSimpleBold as BasketIcon } from "react-icons/pi"
// import { FaShoppingCart as BasketIcon } from "react-icons/fa"
// import { PiShoppingCartSimpleFill as BasketIcon } from "react-icons/pi"
// import { FaBasketShopping as BasketIcon } from "react-icons/fa6"
import { LuCheckCircle as BasketIcon } from "react-icons/lu"

import { ThreeDots as LoadingIcon } from 'react-loading-icons'




import { FiSearch as SearchIcon } from "react-icons/fi"
import IconWithText from "../../ui/IconWithText"
import styles from './Header.module.css'
import { MyContext } from "../../../App"

export function Header({ theme = "light" }) {

      const { AppData, UserData } = useContext(MyContext)

      const vw = UserData?.device?.viewport?.width || false

      const [ open, setOpen ] = useState(false)

      const showDrawer = () => {
            setOpen(true)
      }
      const onClose = () => {
            setOpen(false)
      }

      const [ scrollPosition, setScrollPosition ] = useState(0)

      useEffect(() => {
            const handleScroll = () => setScrollPosition(window.scrollY)
            window.addEventListener('scroll', handleScroll)
            return () => window.removeEventListener('scroll', handleScroll)
      }, [])

      // Define max and min values for svg size and padding
      const maxSvgHeight = 30 // max height of the svg in px
      const minSvgHeight = 25 // min height of the svg in px
      const maxPadding = 20 // max padding of the header in px
      const minPadding = 10 // min padding of the header in px
      const startScroll = 100 // start reduction at 200px
      const endScroll = 500 // finish reduction by 500px

      // Calculate interpolated size and padding based on the current scroll position
      const scrollRange = endScroll - startScroll
      const progress = Math.min(Math.max(scrollPosition - startScroll, 0), scrollRange) / scrollRange
      const interpolatedHeight = vw <= 768 ? minSvgHeight : maxSvgHeight - (progress * (maxSvgHeight - minSvgHeight))
      const interpolatedPadding = vw <= 768 ? minPadding : maxPadding - (progress * (maxPadding - minPadding))

      return (

            <div className={styles.header}>

                  {UserData?.policy?.quote?.price &&

                        <div className={styles.headerUpper}>

                              <Row justify="center">

                                    <Col xs={22} md={20}>

                                          <Row >

                                                <Col flex="100px"><SearchIcon /></Col>

                                                <Col flex="auto" style={{ paddingRight: 25, borderRight: '1px solid rgba(255,255,255,0.5)', display: 'flex', justifyContent: 'flex-end' }}>

                                                      {AppData.loading

                                                            ? <LoadingIcon style={{ justifyContent: 'flex-end', padding: 0, height: 17, width: 40 }} />

                                                            : <IconWithText
                                                                  iconLocation="left"
                                                                  icon={<BasketIcon style={{ color: '#87F336' }} />}
                                                                  // text={<LoadingIcon style={{ padding: 0, height: 10, width: 30 }} />}
                                                                  text={`£${UserData.policy.quote.price}`}
                                                                  style={{ justifyContent: 'flex-end' }}
                                                            />
                                                      }



                                                </Col>

                                                <Col flex="70px" align="right" >LOGIN</Col>

                                          </Row>

                                    </Col>

                              </Row>

                        </div>
                  }

                  <div
                        className={`${styles.headerMiddle} ${theme === "light" ? styles.light : styles.dark}`}
                        style={{ padding: `${interpolatedPadding + 2}px 0 ${interpolatedPadding}px 0` }}
                  >
                        <Row justify="center">

                              <Col xs={22} md={20}>

                                    <Row align="middle" gutter={0}>

                                          <Col flex="auto">

                                                <Logo color={theme === "light" ? 'black' : 'white'} height={interpolatedHeight} />

                                          </Col>

                                          <Col flex="100px" className={styles.navMenu}>

                                                <MenuIcon
                                                      onClick={showDrawer}
                                                      color={theme === "light" ? 'black' : 'white'}
                                                      height={interpolatedHeight}
                                                />

                                                <Drawer title="Basic Drawer" onClose={onClose} open={open}>
                                                      <p>Some contents...</p>
                                                      <p>Some contents...</p>
                                                      <p>Some contents...</p>
                                                </Drawer>

                                          </Col>

                                    </Row>

                              </Col>

                        </Row>

                  </div>

                  {AppData?.loading &&

                        <div className={styles.headerLower}>

                              <Row justify="center">

                                    <Col xs={22} md={20}>

                                          Please wait ..

                                    </Col>

                              </Row>

                        </div>
                  }

            </div>
      )
}
