import { Row, Col, Divider } from "antd"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { MyContext } from "../../../App"
import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"
import { FaRegCircleCheck as CheckIcon } from "react-icons/fa6"


import { BannerCta } from "../../sections/BannerCta"

const Complete = () => {

      const { UserData, setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      return (

            <>

                  <Header theme="dark" />

                  <Content isFullWidth>

                        <Row justify="center">

                              <Col xs={22} md={18}>

                                    <Row justify="start">

                                          <Col span={16} style={{ marginTop: 120, marginBottom: 225 }}>

                                                <h1 style={{ marginTop: 20, fontSize: 44, marginBottom: 10 }}><CheckIcon style={{ fontSize: 32, color: "#31A350", marginRight: 10, verticalAlign: -2 }} />Thank you for choosing us.</h1>
                                                <Divider />
                                                <p style={{ fontSize: 18, marginTop: -10, opacity: 0.5 }}>
                                                      Within the next few minutes, you'll receive an email containing your policy documents and links to other important information. It's also worth checking your spam folder!
                                                </p>
                                                <p style={{ fontSize: 18, opacity: 0.5 }}>
                                                      Viewing, changing or cancelling your insurance can all be completed online 24/7. No extra fees apply.
                                                </p>

                                                <p style={{ fontSize: 18, marginBottom: 25, opacity: 0.8, color: "#3F80EC" }}>
                                                      ~link to self serve portal
                                                </p>

                                          </Col>

                                    </Row>

                              </Col>

                        </Row>

                        <BannerCta style={{ marginTop: 0 }} />

                  </Content>

                  <Footer />

            </>

      )
}

export default Complete