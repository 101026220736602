import { Row, Col, Button, Divider, Checkbox, Typography } from "antd"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { MyContext } from "../../../App"
import { Header } from "../../common/Header"
import { Footer } from "../../common/Footer"
import { Content } from "../../common/Content"
import { Back } from "../../ui/Back"
import { FaRegUserCircle as UserIcon } from "react-icons/fa"
import { FaBuildingShield as BusinessIcon } from "react-icons/fa6"
import { FaShieldAlt as CoverIcon } from "react-icons/fa"
// import { RiMoneyPoundCircleLine as BillingIcon } from "react-icons/ri"
import { FaMoneyCheck as BillingIcon } from "react-icons/fa"

import { BannerCta } from "../../sections/BannerCta"

const { Link } = Typography

const Review = () => {

      const { UserData, setUserData } = useContext(MyContext)

      const navigate = useNavigate()

      const startPayment = () => {
            navigate("/pay")
      }

      return (

            <>

                  <Header theme="dark" />

                  <Content isFullWidth>

                        <Row justify="center">

                              <Col xs={22} md={18}>

                                    <Row justify="start">

                                          <Col span={20}>

                                                <Back align="left" style={{ marginTop: 100, marginBottom: 0 }} />

                                                <h1 style={{ marginTop: 20, fontSize: 44, marginBottom: 10 }}>Review your policy</h1>
                                                <p style={{ fontSize: 18, marginTop: -10, marginBottom: 25, opacity: 0.5 }}>
                                                      This is the last screen before payment. Check everything carefully and feel free<br />to go back and change any details as many times as you wish. Measure twice, cut once.
                                                </p>

                                                <Divider style={{ marginBottom: 75 }} />

                                                <Row >

                                                      <Col xs={24} md={6}>

                                                            <BusinessIcon style={{ fontSize: 64, opacity: 0.05 }} />
                                                            <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>Voli Limited</p>
                                                            <p style={{ fontSize: 14, opacity: 0.5, marginTop: 0 }}>
                                                                  Type: Limited Company<br />
                                                                  Established: 2009<br />
                                                                  Annual Revenue: £125-150k
                                                            </p>



                                                      </Col>

                                                      <Col xs={24} md={6}>

                                                            <UserIcon style={{ fontSize: 64, opacity: 0.05 }} />
                                                            <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>Oliver Kingston</p>
                                                            <p style={{ fontSize: 14, opacity: 0.5, marginTop: 0 }}>
                                                                  Date of birth: 15/07/1981<br />
                                                                  Telephone: +44 7917 670401<br />
                                                                  Email: ojkingston@gmail.com
                                                            </p>

                                                      </Col>

                                                      <Col xs={24} md={6}>

                                                            <CoverIcon style={{ fontSize: 64, opacity: 0.05 }} />
                                                            <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>Tools in transit</p>
                                                            <p style={{ fontSize: 14, opacity: 0.5, marginTop: 0 }}>
                                                                  Plan: Essentials<br />
                                                                  Cover limit: £3,000 x 1 vehicle<br />
                                                                  Excess: £100
                                                            </p>

                                                      </Col>

                                                      <Col xs={24} md={6}>

                                                            <BillingIcon style={{ fontSize: 64, opacity: 0.05 }} />
                                                            <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>Billing: Debit Card</p>
                                                            <p style={{ fontSize: 14, opacity: 0.5, marginTop: 0 }}>
                                                                  Net Premium: £69.64<br />
                                                                  Insurance Premium Tax: £8.36<br />
                                                                  Total: £78.00
                                                            </p>

                                                      </Col>

                                                </Row>

                                                <Row style={{ marginTop: 75 }}>

                                                      <Col xs={24} md={8} style={{ paddingRight: 20 }}>
                                                            <div style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
                                                                  <div style={{ backgroundColor: 'rgba(0,0,0,0.8)', color: 'rgba(255,255,255,1)', padding: "10px 25px" }}>
                                                                        <p style={{ verticalAlign: 'sub', fontSize: 18, fontWeight: 700, marginBottom: 0, color: 'rgba(255,255,255,1)', opacity: 0.8 }}>DEMANDS & NEEDS</p>
                                                                  </div>
                                                                  <div style={{ padding: "10px 25px" }}>
                                                                        <p style={{ fontSize: 14, opacity: 0.5, marginTop: 10 }}>
                                                                              Your requested Tools in Transit risk details and sums insured in the above cover summary reflect your demands and needs, as this is a non-advised sale.
                                                                        </p>
                                                                  </div>
                                                            </div>
                                                      </Col>

                                                      <Col xs={24} md={8} style={{ paddingRight: 20 }}>
                                                            <div style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
                                                                  <div style={{ backgroundColor: 'rgba(0,0,0,0.8)', color: 'rgba(255,255,255,1)', padding: "10px 25px" }}>
                                                                        <p style={{ verticalAlign: 'sub', fontSize: 18, fontWeight: 700, marginBottom: 0, color: 'rgba(255,255,255,1)', opacity: 0.8 }}>OUR SERVICE</p>
                                                                  </div>
                                                                  <div style={{ padding: "10px 25px" }}>
                                                                        <p style={{ fontSize: 14, opacity: 0.5, marginTop: 10 }}>
                                                                              We act as your Agent, ensuring the policy aligns with your demands and needs, assist with claims, and offer without advice or recommendations, requiring you to determine its suitability, and we use only Royal & Sun Alliance Insurance Ltd.
                                                                        </p>
                                                                  </div>
                                                            </div>
                                                      </Col>

                                                      <Col xs={24} md={8} style={{ paddingRight: 20 }}>
                                                            <div style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
                                                                  <div style={{ backgroundColor: 'rgba(0,0,0,0.8)', color: 'rgba(255,255,255,1)', padding: "10px 25px" }}>
                                                                        <p style={{ verticalAlign: 'sub', fontSize: 18, fontWeight: 700, marginBottom: 0, color: 'rgba(255,255,255,1)', opacity: 0.8 }}>RELATED COVER</p>
                                                                  </div>
                                                                  <div style={{ padding: "10px 25px" }}>
                                                                        <p style={{ fontSize: 14, opacity: 0.5, marginTop: 10 }}>
                                                                              Our quote covers only Tools in Transit; you may need additional insurance in your line of business, like Commercial Vehicle and Liability insurance, which you haven't requested from us today..
                                                                        </p>
                                                                  </div>
                                                            </div>
                                                      </Col>

                                                </Row>

                                                <Row style={{ marginTop: 75 }}>

                                                      <Col xs={24} md={20}>

                                                            <p style={{ fontSize: 18, fontWeight: 700, marginBottom: 0 }}>IMPORTANT INFORMATION</p>
                                                            <ul style={{ fontSize: 14, marginTop: 50, marginBottom: 50 }}>
                                                                  <li style={{ marginBottom: 20 }}>You are aware that failure to disclose any material fact may result in this insurance being void from inception or may result in your policy not operating fully.</li>
                                                                  <li style={{ marginBottom: 20 }}>You confirm any information provided regarding bankruptcy, insolvency, directors disqualification or criminal convictions have been checked and are correct.</li>
                                                                  <li style={{ marginBottom: 20 }}>You agree to receive your documents by email. If you would prefer to receive a paper copy please contact us.</li>
                                                            </ul>
                                                            <Checkbox>
                                                                  <span style={{ marginLeft: 5 }}>I have read, understood and agree with the above statements.</span>
                                                            </Checkbox>
                                                            <br />
                                                            <Checkbox style={{ marginTop: 10 }}>
                                                                  <span style={{ marginLeft: 5 }}>I have reviewed the <Link href="URL_TO_POLICY_SUMMARY" target="_blank">Policy Summary</Link>,
                                                                        <Link href="URL_TO_POLICY_WORDING" target="_blank"> Policy Wording</Link>, and
                                                                        <Link href="URL_TO_TERMS_OF_BUSINESS" target="_blank"> Terms of Business</Link>.</span>
                                                            </Checkbox>

                                                      </Col>

                                                </Row>

                                                <Button
                                                      type="primary"
                                                      style={{ marginTop: 75, marginBottom: 125 }}
                                                      size="large"
                                                      onClick={() => { startPayment() }}
                                                >
                                                      Make Payment of £{UserData.policy.rating.price}
                                                </Button>

                                          </Col>

                                    </Row >

                              </Col >

                        </Row >

                        <BannerCta style={{ marginTop: 0 }} />

                  </Content >

                  <Footer />

            </>

      )
}

export default Review